import React from 'react';
import Helmet from 'react-helmet';

export interface LayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: LayoutProps) {
  return (
    <>
      <Helmet bodyAttributes={{ class: 'bg-white' }}></Helmet>
      <div className="text-main font-sans overflow-hidden relative">
        <main>{children}</main>
      </div>
    </>
  );
}

export default Layout;
